import React, { useEffect, useState } from 'react';
import { fetchMarkdownContent } from '../utils';
import '../styles/BlogList.css';

interface BlogProps {
    id: number;
    path: string;
    author: string;
    date: string;
    title: string;
    image: string;
}

export default function BlogList() {
    const [files, setFiles] = useState<BlogProps[]>([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    async function fetchFiles() {
        const fileDataPromises = Array.from({ length: 23 }, (_, i) => {
            const filePath = `../posts/post-${i + 1}.md`;
            return fetch(filePath)
                .then((response) => response.text())
                .then((content) => ({
                    id: i + 1, content
                }))
                .catch((error) => {
                    console.error(`Error fetching file ${filePath}`, error);
                    return { id: i + 1, content: 'Error fetching content' };
                });
        });
        const fileData: any = await Promise.all(fileDataPromises);
        fetchMarkdownContentForAllPosts(fileData);
    };


    async function fetchMarkdownContentForAllPosts(files: Array<BlogProps>) {
        const updatedFiles = await Promise.all(
            files.map(async (post: BlogProps) => {

                try {
                    const data = await fetchMarkdownContent(post.id.toString());
                    return {
                        ...post,
                        author: data.data.author,
                        title: data.data.title,
                        date: data.data.date,
                        image: data.data.image
                        // Add other properties as needed
                    };
                } catch (error) {
                    console.error(`Error fetching markdown content for post ${post.id}:`, error);
                    return null;
                }
            })
        );
        const filteredFiles: any = updatedFiles.filter((post) => post !== null);
        const sortedBlogs = filteredFiles.sort((a: any, b: any) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
        setFiles(sortedBlogs);
    };

    function formatDate(inputDate: string) {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    }
    function navigateToBlog(postId: number) {
        window.location.href = `/article/${postId}`
    }
    return (
        <div className="blog-grid">
            {files.map((post: BlogProps) => {
                return <div key={post.id} className="blog-post" onClick={() => navigateToBlog(post.id)}>
                    <img src={`/assets/images/${post.image}`} alt={post.title} className="blog-image" />
                    <div className="blog-content">
                        <p className="blog-title">{post.title}</p>
                        <p className="blog-meta">By {post.author} on {formatDate(post.date)}</p>
                    </div>
                </div>
            })}
        </div>
    );
};